import localhost from "./localhost";
import dev from "./dev";
import sit from "./sit";
import prod from "./prod";
import sit2 from "./sit2";

const getConfig = () => {
  if (window.location.href.includes("localhost")) return localhost;
  else {
    if (window.location.href.includes("dev")) return dev;
    if (window.location.href.includes("sit2")) return sit2;
    if (window.location.href.includes("sit")) return sit;
    return prod;
  }
};

const config = getConfig();

export default config;
