import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { Table } from "capitalroadkit";
import moment from "moment";
import { useMemo } from "react";

import InputFileTagsModal from "./InputFileTagsModal";

import config from "../../config";
import { useAuthorization } from "../../context/AuthorizationContext";
import s3 from "../../s3";

const InputFileTable = ({ data }) => {
  const [authorization] = useAuthorization();

  const columns = useMemo(
    () => [
      {
        id: "InputFileTable",
        columns: [
          {
            accessor: "Key",
            Header: "Name",
            Cell: ({ cell: { value } }) => value.split("/")[1],
          },
          {
            accessor: "LastModified",
            Header: "Last modified",
            Cell: ({ cell: { value } }) => moment(value).format("MMM DD, YYYY HH:mm"),
          },
          {
            id: "actions",
            sort: false,
            Cell: ({ row: { original } }) => (
              <div style={{ display: "flex", gap: "8px" }}>
                <FontAwesomeIcon
                  icon={faDownload}
                  onClick={async () => {
                    const command = new GetObjectCommand({
                      Bucket: config.BUCKET,
                      Key: original.Key,
                    });
                    const url = await getSignedUrl(s3(authorization), command, { expiresIn: 3600 });
                    window.open(url);
                  }}
                  style={{ cursor: "pointer" }}
                />
                <InputFileTagsModal Key={original.Key} />
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      sortBy={[
        {
          id: "LastModified",
          desc: true,
        },
      ]}
    />
  );
};

export default InputFileTable;
