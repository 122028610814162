import { FileField, TextField } from "capitalroadkit";
import { Field } from "formik";
import React from "react";

import withNameSpace from "../../functions/withNameSpace";
import { MenuItem } from "@material-ui/core";

const fields = {
  FILE: "file",
  FILE_TYPE: "fileType",
};

const initialValues = {
  [fields.FILE]: null,
  [fields.FILE_TYPE]: "SSC",
};

const InputFileForm = ({ nameSpace }) => {
  return (
    <>
      <Field component={TextField} label="File type" name={withNameSpace(nameSpace, fields.FILE_TYPE)} select required>
        <MenuItem value="SSC">SSC</MenuItem>
      </Field>
      <Field component={FileField} label="File" name={withNameSpace(nameSpace, fields.FILE)} required />
    </>
  );
};

InputFileForm.fields = fields;
InputFileForm.initialValues = initialValues;

export default InputFileForm;
