const config = {
  REGION: "ap-southeast-2",
  BUCKET: "cr-sit-file-gateway",
  USER_POOL_ID: "ap-southeast-2_1MwNNd6PW",
  USER_POOL_APP_CLIENT_ID: "25cf7enttfe64vdr4393e1jk4p",
  USER_POOL_DOMAIN: "https://sit-nonprod-idp-filegateway-cr.auth.ap-southeast-2.amazoncognito.com",
  IDENTITY_POOL_ID: "ap-southeast-2:d7bfbad9-30d9-43a0-8b6b-e4aee8c3f593",
};

export default config;
