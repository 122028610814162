import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

import config from "./config";

const s3 = (authorization) => {
  return new S3Client({
    region: config.REGION,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: config.REGION }),
      identityPoolId: config.IDENTITY_POOL_ID,
      logins: {
        [`cognito-idp.${config.REGION}.amazonaws.com/${config.USER_POOL_ID}`]: authorization.id_token,
      },
    }),
  });
};

export default s3;
