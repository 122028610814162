import { GetObjectTaggingCommand } from "@aws-sdk/client-s3";
import { faTags } from "@fortawesome/pro-regular-svg-icons/faTags";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { Card, CardActions, CardBody, CardHeader, CardTitle, IconButton, Modal } from "capitalroadkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { useAuthorization } from "../../context/AuthorizationContext";
import s3 from "../../s3";
import config from "../../config";

const InputFileTagsModal = ({ Key }) => {
  const [authorization] = useAuthorization();

  const [open, setOpen] = useState(false);

  const [tags, setTags] = useState([]);

  useEffect(() => {
    const getObjectTags = async () => {
      const command = new GetObjectTaggingCommand({
        Bucket: config.BUCKET,
        Key,
      });
      const data = await s3(authorization).send(command);
      setTags(data.TagSet);
    };

    if (open) getObjectTags();
  }, [open]);

  return (
    <>
      <FontAwesomeIcon icon={faTags} onClick={() => setOpen(true)} style={{ cursor: "pointer" }} />
      <Modal open={open}>
        <Card>
          <CardHeader>
            <CardTitle>File details</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setOpen(false)} />
            </CardActions>
          </CardHeader>
          <CardBody>
            <div style={{ overflowY: "auto" }}>
              <table>
                <tbody>
                  <tr>
                    <th>Cognito email</th>
                    <td>{tags.find((tag) => tag.Key === "cognito-email")?.Value}</td>
                  </tr>
                  <tr>
                    <th>Cognito name</th>
                    <td>{tags.find((tag) => tag.Key === "cognito-name")?.Value}</td>
                  </tr>
                  <tr>
                    <th>File type</th>
                    <td>{tags.find((tag) => tag.Key === "file-type")?.Value}</td>
                  </tr>
                  <tr>
                    <th>File UUID</th>
                    <td>{tags.find((tag) => tag.Key === "uuid")?.Value}</td>
                  </tr>
                  <tr>
                    <th>Service</th>
                    <td>{tags.find((tag) => tag.Key === "service")?.Value}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default InputFileTagsModal;
