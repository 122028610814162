import qs from "qs";
import React, { createContext, useContext } from "react";

import { useAuthorization } from "./AuthorizationContext";

import parseJwt from "../functions/parseJWT";

const UserContext = createContext([null, undefined]);

const UserProvider = ({ children }) => {
  const [authorization] = useAuthorization();

  const user = parseJwt(authorization.id_token);
  user["custom:metadata"] = qs.parse(user["custom:metadata"]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

const useUser = () => useContext(UserContext);

export default UserProvider;
export { useUser };
