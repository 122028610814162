import React, { createContext, useContext, useState } from "react";

const AuthorizationContext = createContext([null, undefined]);

const AuthorizationProvider = ({ children }) => {
  const [authorization, setAuthorization] = useState(null);

  return (
    <AuthorizationContext.Provider value={[authorization, setAuthorization]}>{children}</AuthorizationContext.Provider>
  );
};

const useAuthorization = () => useContext(AuthorizationContext);

export default AuthorizationProvider;
export { useAuthorization };
