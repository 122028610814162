const config = {
  REGION: "ap-southeast-2",
  BUCKET: "cr-localhost-file-gateway",
  USER_POOL_ID: "ap-southeast-2_sJW3eybUA",
  USER_POOL_APP_CLIENT_ID: "24sr857nia5l3uk2tah23fcthr",
  USER_POOL_DOMAIN: "https://file-gateway.auth.ap-southeast-2.amazoncognito.com",
  IDENTITY_POOL_ID: "ap-southeast-2:87644833-0a18-4bb5-8821-aae537605d48",
};

export default config;
