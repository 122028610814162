const config = {
  REGION: "ap-southeast-2",
  BUCKET: "cr-dev-file-gateway",
  USER_POOL_ID: "ap-southeast-2_uQ7DGtw61",
  USER_POOL_APP_CLIENT_ID: "2vertjgmubj4rsjioker7chben",
  USER_POOL_DOMAIN: "https://dev-nonprod-idp-filegateway-cr.auth.ap-southeast-2.amazoncognito.com",
  IDENTITY_POOL_ID: "ap-southeast-2:9dc53f58-00ba-4fe3-922d-957c3be16215",
};

export default config;
