import { GetObjectCommand, ListObjectsV2Command, PutObjectCommand } from "@aws-sdk/client-s3";
import { faSync } from "@fortawesome/pro-regular-svg-icons/faSync";
import {
  Button,
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Grid,
  IconButton,
  InfoBox,
  Page,
  PageHeader,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import InputFileForm from "./InputFileForm";
import InputFileTable from "./InputFileTable";
import OutputFileTable from "./OutputFileTable";

import s3 from "../../s3";
import { useAuthorization } from "../../context/AuthorizationContext";
import config from "../../config";
import { useUser } from "../../context/UserContext";

const FileGateway = () => {
  const [authorization] = useAuthorization();
  const user = useUser();

  const [inputFiles, setInputFiles] = useState([]);
  const [outputFiles, setOutputFiles] = useState([]);

  const [s3Error, setS3Error] = useState(null);

  const getInputFiles = async () => {
    let data = [];

    let response = { NextContinuationToken: true };
    while (response.NextContinuationToken) {
      const command =
        data.length > 1
          ? new ListObjectsV2Command({
              Bucket: config.BUCKET,
              Prefix: "in/",
              StartAfter: data[data.length - 1].Key,
            })
          : new ListObjectsV2Command({
              Bucket: config.BUCKET,
              Prefix: "in/",
            });
      response = await s3(authorization).send(command);
      data = [...data, ...response.Contents];
    }

    setInputFiles(data);
  };

  const getOutputFiles = async () => {
    let data = [];

    let response = { NextContinuationToken: true };
    while (response.NextContinuationToken) {
      const command =
        data.length > 1
          ? new ListObjectsV2Command({
            Bucket: config.BUCKET,
            Prefix: "out/",
            StartAfter: data[data.length - 1].Key,
          })
          : new ListObjectsV2Command({
            Bucket: config.BUCKET,
            Prefix: "out/",
          });
      response = await s3(authorization).send(command);
      data = [...data, ...response.Contents];
    }

    setOutputFiles(data);
  };

  useEffect(() => {
    getInputFiles();
    getOutputFiles();
  }, []);

  const fileForm = useFormik({
    initialValues: InputFileForm.initialValues,
    onSubmit: async (values) => {
      setS3Error(null);

      const Key = `in/${values[InputFileForm.fields.FILE].name}`;

      const getCommand = new GetObjectCommand({
        Bucket: config.BUCKET,
        Key,
      });

      try {
        await s3(authorization).send(getCommand);
        setS3Error("File already exists");
      } catch (error) {
        const putCommand = new PutObjectCommand({
          Bucket: config.BUCKET,
          Key,
          Body: values[InputFileForm.fields.FILE],
          Tagging: `cognito-email=${user.email}&cognito-name=${user.name}&cognito-phone-number=${
            user.phone_number
          }&cognito-sub=${user.sub}&cognito-username=${user["cognito:username"]}&custodian-account-uuid=${
            user["custom:metadata"]["custodian-account-uuid"]
          }&file-type=${values[InputFileForm.fields.FILE_TYPE]}&service=${user["custom:service"]}&uuid=${uuid()}`,
        });

        await s3(authorization).send(putCommand);
        getInputFiles();
        fileForm.resetForm();
      }
    },
  });

  return (
    <Page id="FileGateway">
      <Grid>
        <PageHeader heading="Document hub">
          <Button
            color="primary"
            onClick={() =>
              window.location.replace(
                `${config.USER_POOL_DOMAIN}/logout?client_id=${config.USER_POOL_APP_CLIENT_ID}&logout_uri=${window.location.origin}`
              )
            }
            variant="text"
          >
            Logout
          </Button>
        </PageHeader>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>Upload</CardTitle>
            </CardHeader>
            <CardBody>
              <FormikProvider value={fileForm}>
                <Form>
                  <div style={{ alignItems: "end", display: "flex", gap: "16px" }}>
                    <InputFileForm />
                    <Button color="primary" loading={fileForm.isSubmitting} type="submit">
                      Upload
                    </Button>
                  </div>
                </Form>
              </FormikProvider>
            </CardBody>
          </Card>
        </Col>
        {s3Error && (
          <Col sm={12}>
            <Card>
              <CardBody>
                <InfoBox variant={InfoBox.Variant.ERROR} value={s3Error} />
              </CardBody>
            </Card>
          </Col>
        )}
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>Upload history</CardTitle>
            </CardHeader>
            <InputFileTable
              data={inputFiles.map((file) => ({ ...file, LastModified: moment(file.LastModified).valueOf() }))}
            />
          </Card>
        </Col>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>Output files</CardTitle>
              <CardActions>
                <IconButton icon={faSync} onClick={getOutputFiles} />
              </CardActions>
            </CardHeader>
            <OutputFileTable
              data={outputFiles.map((file) => ({ ...file, LastModified: moment(file.LastModified).valueOf() }))}
            />
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default FileGateway;
