import { globalStyles } from "capitalroadkit";
import injectSheet from "react-jss";

import FileGateway from "./components/FileGateway";
import Login from "./components/Login";
import AuthorizationProvider from "./context/AuthorizationContext";
import UserProvider from "./context/UserContext";

const App = () => {
  return (
    <AuthorizationProvider>
      <Login>
        <UserProvider>
          <FileGateway />
        </UserProvider>
      </Login>
    </AuthorizationProvider>
  );
};

export default injectSheet(globalStyles)(App);
