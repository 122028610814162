import { CognitoAuthenticate, InfoBox } from "capitalroadkit";
import React, { useState } from "react";

import config from "../../config";
import { useAuthorization } from "../../context/AuthorizationContext";

const Login = ({ children }) => {
  const [authorization, setAuthorization] = useAuthorization();

  const [authError, setAuthError] = useState(null);
  const [stateNotMatchError, setStateNotMatchError] = useState(null);

  if (authError) {
    return (
      <div style={{ alignItems: "center", display: "flex", height: "100vh", justifyContent: "center" }}>
        <InfoBox variant={InfoBox.Variant.ERROR} nested value={authError} />
      </div>
    );
  }
  if (stateNotMatchError) {
    return (
      <div style={{ alignItems: "center", display: "flex", height: "100vh", justifyContent: "center" }}>
        <InfoBox variant={InfoBox.Variant.ERROR} nested value="State does not match" />
      </div>
    );
  }
  if (authorization) return children;
  return (
    <CognitoAuthenticate
      cognitoDomainUrl={config.USER_POOL_DOMAIN}
      clientPoolId={config.USER_POOL_APP_CLIENT_ID}
      loginRedirectUrl=""
      setAuthorization={setAuthorization}
      postAuthError={(e) => setAuthError(e)}
      stateNotMatchError={() => setStateNotMatchError(true)}
    />
  );
};

export default Login;
