const config = {
  REGION: "ap-southeast-2",
  BUCKET: "cr-prod-file-gateway",
  USER_POOL_ID: "ap-southeast-2_a8NaSabic",
  USER_POOL_APP_CLIENT_ID: "178ddn6rf15allfc3jrt3nger5",
  USER_POOL_DOMAIN: "https://secure-prod-idp-filegateway-cr.auth.ap-southeast-2.amazoncognito.com",
  IDENTITY_POOL_ID: "ap-southeast-2:7c127e8c-60d1-434a-ae92-ded5f84d9ea9",
};

export default config;
