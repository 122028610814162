const config = {
  REGION: "ap-southeast-2",
  BUCKET: "cr-sit2-file-gateway",
  USER_POOL_ID: "ap-southeast-2_ykG7rbeUF",
  USER_POOL_APP_CLIENT_ID: "6pkhjur7du8ql5u9434cipbnhe",
  USER_POOL_DOMAIN: "https://sit2-nonprod-idp-filegateway-cr.auth.ap-southeast-2.amazoncognito.com",
  IDENTITY_POOL_ID: "ap-southeast-2:9ffcb523-73c1-4e73-9ff3-cac3d766c096",
};

export default config;
